import React, { useState } from 'react';
import {
    Box, Button, Heading, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Stack, Text, useDisclosure, Skeleton
} from '@chakra-ui/react';
import axios from 'axios';
import "sweetalert2";
import Swal from 'sweetalert2';
import { products } from '../Config/products';

const Products = () => {

    const {
        isOpen: isModalOpen,
        onOpen: onModalOpen,
        onClose: onModalClose
    } = useDisclosure();

    const initialRef = React.useRef(null);
    const finalRef = React.useRef(null);

    const [Name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [product, setProduct] = useState('');

    const [loading, setLoading] = useState(false);
    const [imageLoading, setImageLoading] = useState(true); // State to track image loading

    const messageHandler = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            await axios.post(process.env.REACT_APP_MAIL_LINK, { Name, email, phone, product });
            setLoading(false);

            // Close the modal after successful login
            onModalClose();

            Swal.fire({
                title: "Success!",
                text: "Message sent successfully!",
                icon: "success"
            });
        } catch (error) {
            setLoading(false);
        }
    };

    const handleImageLoad = () => {
        setImageLoading(false); // Image has finished loading
    };

    return (
        <>
            <Box p={4}>
                {/* Display Products */}
                <Heading as="h2" size="xl" mb={6} textAlign="center" >Popular <span style={{ color: "orange" }}>Products</span></Heading>
                <Stack spacing={9} className='d-flex flex-row flex-wrap'>
                    {products.map((product, index) => (
                        <Box style={{ width: "21rem" }} key={index} p={4} boxShadow="md" borderRadius="md" textAlign="center" borderWidth="1px">
                            <Skeleton isLoaded={!imageLoading} height="200px">
                                <Image
                                    src={product.image}
                                    alt={product.title}
                                    objectFit="cover"
                                    mx="auto"
                                    onLoad={handleImageLoad} // Handle the image load event
                                />
                            </Skeleton>
                            <Heading as="h6" size="md" mt={4}>
                                {product.title}
                            </Heading>
                            <Text fontSize="lg" color="blue.500" mt={2}>
                                {product.price} (negotiable)
                            </Text>
                            <Button colorScheme='orange' onClick={() => { setProduct(product.title); onModalOpen() }}>Contact Us</Button>
                        </Box>
                    ))}
                </Stack>
            </Box>

            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isModalOpen}
                onClose={onModalClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody>
                        <div className='w-100'>
                            <h5 className='text-center'>Send <span style={{ color: "orange" }}>Message</span></h5>
                        </div>

                        <form onSubmit={messageHandler}>
                            <div className='my-2'>
                                <label htmlFor="Name">Name:</label>
                                <input
                                    className='form-control'
                                    type="text"
                                    value={Name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder="Enter your Name"
                                    required
                                />
                            </div>
                            <div className='my-2'>
                                <label htmlFor="Email">Email:</label>
                                <input
                                    className='form-control'
                                    type="text"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Enter your Email"
                                    required
                                />
                            </div>
                            <div className='my-2'>
                                <label htmlFor="phone">Phone:</label>
                                <input
                                    className='form-control'
                                    type="text"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    placeholder="Phone no."
                                    required
                                />
                            </div>
                            <div className='my-2'>
                                <label htmlFor="product">Product:</label>
                                <input
                                    className='form-control'
                                    type="text"
                                    value={product}
                                    placeholder="Message"
                                    readOnly
                                    required
                                />
                            </div>
                            <Button type='submit' variant="outline" colorScheme='orange' className='my-4' style={{ float: "right" }} isLoading={loading} >Send</Button>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default Products;
