import React, { useState, useEffect } from 'react';
import {
    Button, ButtonGroup, Card, CardBody, CardFooter, Heading, Text, Skeleton, SkeletonText
} from '@chakra-ui/react';
import { courses } from '../Config/Api';

const Cources = () => {
    const [isLoading, setIsLoading] = useState(true);

    // Simulate loading delay
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1500); // Simulates a loading delay of 1.5 seconds
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className='mx-2 py-4'>
            <div className='container pt-lg-4'>
                <h4 className='text-center'>POPULAR <span style={{ color: "orange" }}>COURSES</span></h4>
                <h2 className='text-center my-5'>Get Started with the Courses</h2>

                <div className='d-flex flex-wrap gap-5 justify-content-center'>
                    {courses.map((curElm, index) => (
                        <Card key={index} style={{ width: "18rem" }}>
                            <div style={{ position: "relative" }}>
                                {isLoading ? (
                                    <Skeleton height="200px" width="100%" />
                                ) : (
                                    <img
                                        className="w-100"
                                        style={{ opacity: 0.6, objectFit: "cover", width: "100%", height: "200px" }}
                                        src={curElm.img}
                                        alt={curElm.img}
                                        loading="lazy"
                                    />
                                )}
                                {!isLoading && (
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            width: "100%",
                                            height: "100%",
                                            backgroundColor: "rgba(0, 0, 0, 0.4)",
                                        }}
                                    ></div>
                                )}
                                {!isLoading && (
                                    <Heading
                                        style={{
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            color: "white",
                                        }}
                                        className="text-center"
                                        size="md"
                                    >
                                        {curElm.name}
                                    </Heading>
                                )}
                            </div>

                            <CardBody>
                                {isLoading ? (
                                    <SkeletonText mt="4" noOfLines={4} spacing="4" />
                                ) : (
                                    <>
                                        <div>
                                            {curElm.description.map((des, index) => (
                                                <Text key={index}>{des}</Text>
                                            ))}
                                        </div>

                                        <div className='d-flex gap-2'>
                                            <Text as="s">{curElm.prevPrice}</Text>
                                            <Text>{curElm.currentPrice}</Text>
                                        </div>
                                    </>
                                )}
                            </CardBody>

                            <CardFooter className='w-100'>
                                {isLoading ? (
                                    <Skeleton height="40px" width="100%" />
                                ) : (
                                    <ButtonGroup spacing='2'>
                                        <Button variant='solid' colorScheme='orange'>
                                            Buy now
                                        </Button>
                                        <Button variant='ghost' colorScheme='orange'>
                                            Add to cart
                                        </Button>
                                    </ButtonGroup>
                                )}
                            </CardFooter>
                        </Card>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Cources;
