import React, { useEffect, useState } from 'react'; // Added useState import
import { Route, Routes } from 'react-router-dom';
import { CircularProgress } from '@chakra-ui/react'; // Ensure Progress is imported
import Home from './Pages/Home';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import VerifyCert from './Pages/VerifyCert';
import Cources from './Pages/Cources';
import Products from './Pages/Products';
import Jobs from './Pages/Jobs';
import Internship from './Pages/Internship';
import Error from './Components/Error';
import Searchbar from './Components/Searchbar';
import { UserState } from './UserContext';
import FooterBar from './Components/FooterBar';
import Compete from './Pages/Compete';
import Mentorship from './Pages/Mentorship';
import ScrollToTop from './Components/ScrollToTop';

const App = () => {

  const [loading, setLoading] = useState(true);

  const { searchClick, setSearchClick } = UserState()

  useEffect(() => {
    const handlePageLoad = () => {
      setLoading(false);
    };

    if (document.readyState === 'complete') {
      setLoading(false); // Page has already loaded
    } else {
      window.addEventListener('load', handlePageLoad); // Listen for page load event
    }

    return () => {
      window.removeEventListener('load', handlePageLoad); // Cleanup event listener
    };
  }, []); // Added an empty dependency array so this effect only runs once on mount




  return (
    <div style={{ position: "relative" }}>
      <div className='w-100' style={{ position: "sticky", top: 0, left: 0, zIndex: 100 }}>
        <Navbar />
      </div>

      {searchClick === true ? (
        <div style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(0,0,0,0.4)",
          zIndex: 1000,
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
          overflow: "hidden" // Prevent scrolling
        }} >
          <div style={{
            width: "90%",
            maxWidth: "600px",
            backgroundColor: "#fff",
            borderRadius: "25px",
            padding: "15px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            marginTop: "5rem"
          }}>
            <Searchbar
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "20px",
                border: "1px solid #ccc",
                fontSize: "16px"
              }}
            />
          </div>
          <div style={{ position: "absolute", right: "4%", top: "2%" }}>
            <svg onClick={() => { setSearchClick(!searchClick) }} style={{ cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" viewBox="0 0 32 32">
              <path fill="#ffffff" d="M17.414 16L24 9.414L22.586 8L16 14.586L9.414 8L8 9.414L14.586 16L8 22.586L9.414 24L16 17.414L22.586 24L24 22.586z" />
            </svg>
          </div>
        </div>
      ) : ""}

      <ScrollToTop />

      {
        loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          >
            <CircularProgress
              isIndeterminate
              size={["35px", "40px", "50px"]}
              color="orange.400"
              thickness="6px"
            />
          </div>
        ) : (

          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/internship" element={<Internship />} />
            <Route exact path="/jobs" element={<Jobs />} />
            <Route exact path="/products" element={<Products />} />
            <Route exact path="/cources" element={<Cources />} />
            <Route exact path="/verify-certificate" element={<VerifyCert />} />
            <Route exact path="/compete" element={<Compete />} />
            <Route exact path="/mentorship" element={<Mentorship />} />
            <Route path="*" element={<Error />} />
          </Routes>

        )
      }


      <Footer />
      <div className='footerMenuBar' style={{ position: "fixed", bottom: "0", width: "100%", right: "0", height: "12%", zIndex: "100", background: "white" }}>
        <FooterBar />
      </div>
    </div>
  );
};

export default App;
