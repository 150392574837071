import React, { useEffect, useState } from 'react';
import { courses, dash1, dash2, img2, programs, popularity } from '../Config/Api';
import { Button, Card, CardBody, CardHeader, Heading, Text, CardFooter, Divider, AbsoluteCenter, Box, ButtonGroup, Skeleton, SkeletonText } from '@chakra-ui/react';
import { TypeAnimation } from 'react-type-animation';
import { NavLink } from 'react-router-dom';
import PopularityCard from '../Components/PopularityCard';


const Home = () => {

    const [isLoading, setIsLoading] = useState(true);

    // Simulate loading delay
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1500); // Simulates a loading delay of 1.5 seconds
        return () => clearTimeout(timer);
    }, []);
    return (
        <>
            <div className='home_section container d-flex align-items-center mt-5 flex-column flex-lg-row'>
                <div className='col-12 col-lg-6'>
                    <h1>Level Up Your <span style={{ color: "orange" }}>Skill's</span></h1>
                    <TypeAnimation
                        sequence={[
                            // Same substring at the start will only be typed once, initially
                            'We Are a Team of Web Developer',
                            1000,
                            'We Are a Team of App Developer',
                            1000,
                            'We Are a Team of SMM',
                            1000,
                            'We Are a Team of Data Analyst',
                            1000,
                        ]}
                        wrapper="span"
                        cursor={true}
                        repeat={Infinity}
                        style={{ display: 'inline-block' }}
                        className='typeAni'
                    />
                    <p className='mt-5'>"Elevate your career with Bluhills. Embark on an exciting internship journey with us and unlock new opportunities for growth."</p>
                    <NavLink to="/internship"><Button colorScheme='orange' variant='outline'>
                        Register Now
                    </Button></NavLink>
                </div>
                <div className='col-12 col-lg-6'>
                    <img className='w-100' src={dash2} alt={dash1} />
                </div>
            </div>


            {/* Virtal internship for remote Learinng */}

            <div className='container mt-lg-5'>

                <Box position='relative' padding='10'>
                    <Divider />
                    <AbsoluteCenter bg='white' px='4'>
                        <h6 style={{ color: "orange" }}>CATEGORIES</h6>
                    </AbsoluteCenter>
                </Box>

                <h1 className='text-center mt-lg-5'>Virtual <span style={{ color: "orange" }}>Internships</span> For Remote Learning.</h1>
                <p className='text-center my-lg-5'>Several online programs provided for remote working are below</p>

                <div className='col-12 d-flex flex-wrap gap-5 justify-content-center'>
                    {programs.map((curElm, index) => {
                        return (
                            <>
                                <Card key={index} style={{ width: "18rem", background: `${curElm.card_Style.bgcolor}` }}>
                                    <div className='d-flex justify-content-center mt-4'>
                                        <div className='d-flex justify-content-center align-items-center' style={{ width: "4rem", height: "4rem", borderRadius: "50%", background: `${curElm.card_Style.circle}` }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" viewBox="0 0 14 14">
                                                <g fill="none" stroke="black" stroke-linecap="round" stroke-linejoin="round">
                                                    <path d="M13 2H1a.5.5 0 0 0-.5.5v8a.5.5 0 0 0 .5.5h12a.5.5 0 0 0 .5-.5v-8A.5.5 0 0 0 13 2m-7 9l-1 2.5M8 11l1 2.5m-5 0h6" />
                                                    <path d="m4.5 5.25l-1.75 1.5L4.25 8m5.5-2.5l1.5 1.25l-1.75 1.5m-3.25.5l1.5-4.5" />
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                    <CardHeader>
                                        <Heading className='text-center' size='md'>{curElm.name}</Heading>
                                    </CardHeader>
                                    <CardBody>
                                        <Text>{curElm.description}</Text>
                                    </CardBody>
                                    <CardFooter>
                                        <Button style={{ background: `${curElm.card_Style.circle}`, color: `${curElm.card_Style.fcolor}` }}>{curElm.courses} courses</Button>
                                    </CardFooter>
                                </Card>
                            </>
                        )
                    })}
                </div>
            </div>


            {/* About Us */}

            <div className='container d-flex flex-wrap mt-5 mt-sm-5' >
                <div className='col-12 col-lg-6 p-lg-5 '>
                    <img className='w-100' src={img2} alt={img2} />
                </div>
                <div className='col-12 col-lg-6 d-flex flex-column justify-content-center'>

                    <Box position='relative' padding='10'>
                        <Divider />
                        <AbsoluteCenter bg='white' px='4'>
                            <h4 className='text-center my-lg-5'>About <span style={{ color: "orange" }}>Us</span></h4>
                        </AbsoluteCenter>
                    </Box>

                    <h1 >WHY CHOOSE US ?</h1>
                    <p className='mt-4'>BlueHills is a leading Ed-Tech platform, quickly shaping future tech innovators. With top internships and certifications, we've seen over 129,000+ students complete our programs, and 95% of certified learners boost their practical tech skills. We emphasize hands-on learning with real projects and flexible pacing. Our strong alumni network and partnerships with top tech firms offer added support and career opportunities. At BlueHills, we're building tech leaders and showcasing success with every story.

                    </p>
                </div>
            </div>


            {/* Courses Available */}

            <div className='mx-2 py-4' style={{ background: "hsl(0, 0%, 65%)" }} >
                <div div className='container pt-lg-4'>
                    <h4 className='text-center'>POPULAR <span style={{ color: "orange" }}>COURSES</span></h4>
                    <h2 className='text-center my-5'>Get Started with the Courses</h2>

                    <div className='d-flex flex-wrap gap-5 justify-content-center'>
                        {courses.map((curElm, index) => {
                            return (
                                <>
                                    <Card key={index} style={{ width: "18rem" }}>
                                        <div style={{ position: "relative" }}>
                                            {isLoading ? (
                                                <Skeleton height="200px" width="100%" />
                                            ) : (
                                                <img
                                                    className="w-100"
                                                    style={{ opacity: 0.6, objectFit: "cover", width: "100%", height: "200px" }}
                                                    src={curElm.img}
                                                    alt={curElm.img}
                                                    loading="lazy"
                                                />
                                            )}
                                            {!isLoading && (
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        top: 0,
                                                        left: 0,
                                                        width: "100%",
                                                        height: "100%",
                                                        backgroundColor: "rgba(0, 0, 0, 0.4)",
                                                    }}
                                                ></div>
                                            )}
                                            {!isLoading && (
                                                <Heading
                                                    style={{
                                                        position: "absolute",
                                                        top: "50%",
                                                        left: "50%",
                                                        transform: "translate(-50%, -50%)",
                                                        color: "white",
                                                    }}
                                                    className="text-center"
                                                    size="md"
                                                >
                                                    {curElm.name}
                                                </Heading>
                                            )}
                                        </div>

                                        <CardBody>
                                            {isLoading ? (
                                                <SkeletonText mt="4" noOfLines={4} spacing="4" />
                                            ) : (
                                                <>
                                                    <div>
                                                        {curElm.description.map((des, index) => (
                                                            <Text key={index}>{des}</Text>
                                                        ))}
                                                    </div>

                                                    <div className='d-flex gap-2'>
                                                        <Text as="s">{curElm.prevPrice}</Text>
                                                        <Text>{curElm.currentPrice}</Text>
                                                    </div>
                                                </>
                                            )}
                                        </CardBody>

                                        <CardFooter className='w-100'>
                                            {isLoading ? (
                                                <Skeleton height="40px" width="100%" />
                                            ) : (
                                                <ButtonGroup spacing='2'>
                                                    <Button variant='solid' colorScheme='orange'>
                                                        Buy now
                                                    </Button>
                                                    <Button variant='ghost' colorScheme='orange'>
                                                        Add to cart
                                                    </Button>
                                                </ButtonGroup>
                                            )}
                                        </CardFooter>
                                    </Card>
                                </>
                            )
                        })}
                    </div>
                </div>
            </div>


            <div className="mx-2" style={{ position: "relative", marginBottom: "10rem" }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path
                        fill="hsl(0, 0%, 65%)"
                        fillOpacity="1"
                        d="M0,224L48,197.3C96,171,192,117,288,128C384,139,480,213,576,234.7C672,256,768,224,864,186.7C960,149,1056,107,1152,112C1248,117,1344,171,1392,197.3L1440,224L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
                    ></path>
                </svg>
                <div
                    className="col-12 d-flex justify-content-center align-items-center mt-5"
                    style={{
                        position: "absolute",
                        zIndex: 10,
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "100%",
                        height: "100%",
                        padding: "20px",
                        boxSizing: "border-box",
                    }}
                >
                    <iframe
                        width="100%"
                        height="100%"
                        style={{
                            minWidth: "350px",
                            minHeight: "200px",
                            maxWidth: "560px",
                            maxHeight: "315px",
                            aspectRatio: "16/9",
                        }}
                        src="https://www.youtube.com/embed/SF4aHwxHtZ0?si=9JtmgSbpBlV1EF3s"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                    ></iframe>
                </div>
            </div>




            <div className='d-flex flex-wrap justify-content-center gap-5 container my-5'>
                {popularity.map((curElm, index) => (
                    <PopularityCard key={index} curElm={curElm} />
                ))}
            </div>




        </>
    )
}

export default Home
